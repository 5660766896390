.message-page {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: calc(100vh - 100px);
}
.group-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 100%;
    background-color: var(--backgroundSecondaryLight);
    overflow-x: auto;
}
.group-item {
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-weight: bold;
    height: 30px;
    padding: 3px 10px;
    border-radius: 15px;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: fit-content;
}
.group-item.active {
    background-color: var(--secondary);
    color: var(--contrast-text);
}
.group-item .MuiSvgIcon-root {
    font-size: 1rem;
    margin-right: 5px;
}
.message-page .posts {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
}
.post-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.post-item.my-post {
    flex-direction: row-reverse;
}
.post-item .post-name{
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
    color: var(--primary);
}
.post-item .post-time{
    margin-top: 5px;
    font-size: 10px;
    text-align: right;
    color: rgba(0, 0, 0, 0.3);
    font-weight: bold;
}
.post-item .post-message{
    margin: 10px;
    border-radius: 20px;
    background-color: var(--backgroundPrimaryLight);
    padding: 10px;
}
.message-page .create-post {
    background-color: var(--backgroundSecondaryLight);
    padding: 20px;
    display: flex;
    flex-direction: row;
}
.message-page .create-post .MuiInputBase-root {
    border-radius: 10px !important;
    background-color: white;
}
.message-page .create-post .send-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.message-page .create-post .button {
    margin-left: 20px;
}
@media screen and (max-width: 400px){
    .message-page .create-post {
        padding: 10px;
    }
    .message-page .create-post .button {
        margin-left: 10px;
    }
}
