.button {
    border-radius: 10px !important;
}
.button_round {
    border-radius: 100px !important;
}
.button_highlight {
    background-color: var(--primary);
    background: linear-gradient(45deg, var(--primary), var(--secondary));
}
.button_standardWidth {
    min-width: 250px !important;
}
.button_fullWidth {
    width: 100% !important;
}
.button_error.MuiButton-outlined {
    border: 1px solid var(--error);
    color: var(--error);
}
.button_error.MuiButton-contained {
    background-color: var(--error);
    color: var(--contrast-text);
}
.button_error.MuiButton-contained:hover {
    background-color: var(--error);
}
.button_error.MuiButton-text {
    color: var(--error);
}
.button_white.MuiButton-outlined {
    border: 1px solid var(--contrast-text);
    color: var(--contrast-text);
}
.button_white.MuiButton-contained {
    background-color: var(--contrast-text);
}
.button_white.MuiButton-text {
    color: var(--contrast-text);
}
