.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 20px auto;
    padding: 0 10px;
}
.timeline .timeline-line {
    position: absolute;
    width: 2px;
    top: 0;
    bottom: 0;
    left: 50%;
    left: calc(50% - 1px);
    z-index: 0;
    background-color: var(--primary);
}
@media screen and (max-width: 800px) and (min-width: 500px){
    .timeline {
        align-items: flex-start;
    }
    .timeline .timeline-line {
        left: 54px;
    }
}
@media screen and (max-width: 499px){
    .timeline .timeline-line {
        display: none;
    }
}
