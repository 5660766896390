.person-items {
    display: flex;
    flex-direction: row;
    width: 900px;
    max-width: 100vw;
    flex-wrap: wrap;
    justify-content: center;
}
.person-item {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 300px;
    max-width: 500px;
}
.person-details h3 {
    margin-bottom: 0;
}
