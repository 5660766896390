.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 90px;
    background-color: var(--primary);
    z-index: 10;
    color: var(--contrast-text);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background: linear-gradient(45deg, var(--primary), var(--secondary));
}
.nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}
.header .navItem{
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    cursor: pointer;
}
.header .logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    cursor: pointer;

}
.header .logo img{
    max-height: 90px;
    max-width: 300px;
    object-fit: contain;
}
.header .nav .navItem:hover {
    background-color: rgba(255,255,255,0.1);
}
.header .nav .navItem.active {
    background-color: rgba(255,255,255,0.2);
}
.internal-layout-content {
    margin-top: 90px;
}
@media only screen and (max-width: 767px) {
    .header {
        flex-direction: column;
        height: 9s0px;

    }
    .header .logo {
        margin-top: 5px;
    }
    .header .logo img{
        max-height: 50px;
        width: calc(100vw - 40px);
        max-width: calc(100vw - 40px);
        object-fit: contain;
    }
    .header .navItem{
        justify-content: center;
        font-size: 12px;
        text-align: center;
        flex: 1;
        height: 40px;
        white-space:nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .header .navItem.active {
        background-color: rgba(255,255,255,0.2);
        background: linear-gradient(0deg, rgba(255,255,255,0.2), rgba(255,255,255,0));
    }
    .header .navItem:first-child {
        border-top-left-radius: 0;
    }
    .header .navItem:last-child {
        border-top-right-radius: 0;
    }
    .header .navItem span{
        text-align: center;
    }
}
