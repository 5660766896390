.full-height-page {
    position: relative;
    width: 100vw;
}
.scroll-arrow {
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -14px;
    z-index: 3;
    cursor: pointer;
}
@media only screen and (max-width: 767px) {
    .full-height-page {
        height: auto;
    }
    .full-height-page .image-wrapper {
        margin: 55px 0 55px 0;
    }
    .full-height-page .scroll-arrow {
        display: none;
    }
}
