body {
    background-color: var(--background);
}
*, *::before, *::after {
    box-sizing: border-box;
}
.App {
    /* Center and scale the image nicely */
}

.flyer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 90vw;
    vertical-align: top;
}
.fullscreen-image {
    object-fit: cover;
    width: 100% !important;
    vertical-align: top;
}
@media (min-width: 600px) and (orientation:landscape) {
    .flyer {
        height: 80vh;
        width: unset;
    }
}
h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.7em;
}
h3 {
    font-size: 1.3em;
}
h4 {
    font-size: 2em;
    font-family: 'Handlee', sans-serif;
}
h5 {
    font-size: 1.7em;
    font-family: 'Handlee', sans-serif;
}
h6 {
    font-size: 1.3em;
    font-family: 'Handlee', sans-serif;
}
@media screen and (max-width: 400px){
    h1, h4 {
        font-size: 2em;
    }
    h2, h5 {
        font-size: 1.7em;
    }
    h3, h6 {
        font-size: 1.3em;
    }
    html {
        zoom: 1;
    }
}
.center-text {
    margin: auto;
    text-align: center;
}
.contrast-text {
    color: var(--contrast-text)
}
.content-box {
    padding: 20px;
    max-width: 500px;
}
.content-box img {
    max-width: 100%;
}
.big-content-box {
    margin: 0 auto 0 auto;
    max-width: 700px;
}
.centered-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
}
.center-vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.align-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.align-vertical  {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.stretch-vertical  {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}
.space-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.margin-item:not(:last-child) {
    margin-right: 20px;
}

.handwritten {
    font-family: 'Handlee', sans-serif;
}
/*
    Editor
 */
.public-DraftStyleDefault-ltr {
    text-align: center !important;
}
.rdw-image-modal,
.rdw-emoji-modal,
.rdw-embedded-modal,
.rdw-link-modal,
.rdw-dropdown-optionwrapper {
    border-radius: 10px !important;
}
.editor-wrapper {
    background-color: var(--backgroundPrimaryLight);
    border-radius: 20px;
    margin: -20px;
    margin-top: 20px;
}
.editor-wrapper .rdw-editor-toolbar {
    border-radius: 20px;
    position: fixed;
    z-index: 9;
    max-width: 500px;
}
.editor-wrapper .rdw-option-wrapper {
    height: 30px;
    width: 30px;
    border-radius: 15px;
}
.editor-wrapper .rdw-dropdown-wrapper {
    height: 30px;
    border-radius: 15px;
}
.editor-wrapper .editor {
    padding: 20px;
    padding-top: 100px;
}
.editor-wrapper button {
    border-radius: 10px;
}
/*
    Loader Background
*/
.loader-background {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: var(--background);
    z-index: 9999;
}
.loader-background-transparent {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 9999;
    background-color: rgba(0,0,0,.1);
    backdrop-filter: saturate(140%) blur(10px);
}
.ril__outer {
    backdrop-filter: saturate(140%) blur(10px);
}
.ril-toolbar-button {
    width: 40px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    padding-top: 5px;
    opacity: 0.75;
}
.ril-toolbar-button:hover {
    opacity: 1;
}
.MuiOutlinedInput-root, .MuiSelect-outlined {
    border-radius: 10px !important;
}
.MuiOutlinedInput-input {
    padding: 12px 40px 12px 20px !important;
}
.roundInput .MuiInputLabel-outlined {
    transform: translate(14px, 15px) scale(1);
}
.overlay {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(255,255,255,.1);
    backdrop-filter: saturate(140%) blur(5px);
}
.bottom-action-bar {
    position: fixed;
    bottom: -150px;
    left: 20px;
    right: 20px;
}
.bottom-action-bar .MuiPaper-root {
    background-color: rgba(255,255,255,.9);
    backdrop-filter: saturate(140%) blur(5px);
}
.panel {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: var(--background);
    padding: 20px;
    margin-top: 30px;
    border-radius: 20px;
}
.panel.bordered {
    border: 1px solid rgba(0, 0, 0, 0.23);
}
.panel.primary {
    background: var(--primary);
    color: var(--contrast-text);
    border-width: 0;
}
.panel.secondary {
    background: var(--secondary);
    color: var(--contrast-text);
    border-width: 0;
}
.panel.primary.bordered {
    border: 3px solid var(--primary);
    background: var(--backgroundPrimaryLight);
    color: var(--primary);
}
.panel.secondary.bordered {
    border: 3px solid var(--secondary);
    background: var(--backgroundSecondaryLight);
    color: var(--secondary);
}
.indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary);
}
.indicator-label.handwritten {
    font-weight: bold;
}
.indicator-number {
    font-size: 40px;
    font-weight: bold;
}
.indicator-label {
    font-size: 20px;
}
.content-page-preview{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}
.simple-color-switch{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.23);
    flex: 0 0 auto;
}
.image-content {
    position: relative;
}
.image-content .text-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
}
.image-content .text-content.preview {
    transform: scale(0.5);
}
.action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.action-buttons .button {
    margin-right: 10px;
}
.action-buttons .button:last-child {
    margin-right: 0;
}
@media screen and (max-width: 400px){
    .action-buttons {
        flex-direction: column;
    }
    .action-buttons .button {
        margin-bottom: 10px;
        margin-right: 0;
    }
    .action-buttons .button:last-child {
        margin-bottom: 0;
        margin-right: 0;
    }
    .editor-wrapper {
        border-radius: 0;
        margin-top: -20px;
    }
    .editor-wrapper .rdw-editor-toolbar {
        border-radius: 0;
        position: relative;
    }
    .indicator-number {
        font-size: 30px;
        font-weight: bold;
    }
    .indicator-label {
        font-size: 15px;
    }
    .content-page-preview{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .editor-wrapper .editor {
        padding-top: 20px;
    }
}
.top-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    justify-content: flex-end;
    z-index: 1;
}
.simpleBorderArea {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
ul.simpleList {
    padding-left: 0;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.23);
}
ul.simpleList li{
    list-style-type: none;
    margin-left: 0;
    padding: 5px;
    text-align: left;
}
ul.simpleList.spacer > li:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.10);
}
ul.simpleList li.even{
    background-color: var(--backgroundSecondaryLight);
}
ul.simpleList li.uneven{
}
ul.guestList > li {
    padding: 20px;
    cursor: pointer;
}
ul.guestList > li:hover {
    background-color: var(--backgroundPrimaryLight);
}
ul.guestList > li .guest{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
ul.guestList li .guest .guest-name {
    font-weight: bold;
}
.bigBorder {
    border-radius: 20px !important;
}
.info-text {
    text-align: left;
    font-size: 0.8em;
}
.fixed-width {
    margin-left: auto;
    margin-right: auto;
    width: calc(100vw - 40px);
    min-width: 280px;
    max-width: 320px;
}
.error-text {
    color: var(--error);
    text-align: left;
}
.image-dropzone {
    width: 100%;
    height: 250px;
    background-color: var(--backgroundPrimaryLight);
    border: 3px dashed var(--primary);
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 17px;
    cursor: pointer;
}
.image-dropzone .MuiSvgIcon-fontSizeLarge {
    font-size: 100px;
}
.MuiMenu-paper {
    /*min-width: 200px !important;*/
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiFormControlLabel-root {
    text-align: left;
}
.MuiDialog-paper.MuiPaper-rounded,
.MuiMenu-paper.MuiPaper-rounded {
    border-radius: 10px !important;
}
.accordion-value {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.value-preview {
    color: var(--primary);
}
.tagline {
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-style: italic;
    font-size: 13px;
}
blockquote {
    margin: 10px 0;
    padding: 10px 10px;
    border-left: 5px solid var(--primary);
    background-color: var(--backgroundPrimaryLight);
    border-radius: 5px;
}
a {
    color: var(--primary);
    text-decoration: none;
}
a:hover {
    color: var(--secondary);
}
/*
    Person Image
 */
.person-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}
.person-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 75px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    /*overflow: hidden;*/
}
.person-image img {
    width: 150px;
    height: 150px;
    border-radius: 75px;
}

.person-image .image-overlay {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    transition: opacity 0.5s ease-in-out;
}
.person-image .image-overlay.full-opacity {
    opacity: 0.7;
}
.person-image .image-overlay:hover {
    opacity: 0.7;
}
.person-image .image-overlay.full-opacity:hover {
    opacity: 1;
}
.person-image .image-overlay .MuiSvgIcon-root {
    font-size: 70px;
    color: var(--background);
}
@media screen and (max-width: 400px) {
    .align-vertical-mobile {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        text-align: left;
    }
    .align-vertical-mobile > .margin-item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
    }
}
