.icon-picker {
    border: 2px solid var(--primary);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-radius: 10px;
    overflow: hidden;
}
.icon-picker .icon-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    cursor: pointer;
}
.icon-picker .icon-button:hover {
    opacity: 0.8;
}
.icon-picker .icon-button svg {
    margin: 20px;
    height: 50px;
    width: 50px;
}
.icon-picker .icon-list {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}
.icon-picker .icon-list .icon-item {
    padding: 10px;
    height: 55px;
    width: 55px;
    cursor: pointer;
    border-radius: 27.5px;
}
.icon-picker .icon-list .icon-item svg {
    height: 35px;
    width: 35px;
}
.icon-picker .icon-list .icon-item:hover {
    background-color: var(--backgroundSecondaryLight);

}
.icon-picker .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}
.icon-picker .content.open {
    padding: 5px;
}

@media screen and (max-width: 500px) {
    .icon-picker {
        flex-direction: column;
    }
}
