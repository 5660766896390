.crop-container {
    position: relative;
    width: 500px;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
}
.crop-controls {
    margin: 20px;
}
.crop-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
}
