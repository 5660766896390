.location-item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
}
.location-item .location-details{
    text-align: left;
}
.location-item .location-details h3{
    margin-bottom: 0;
    margin-top: 0;
}
.location-item .location-details .location-address {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.location-item .location-details .location-address a {
    margin-left: 5px;
}
.location-preview {
    margin-right: 40px;
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 100px;
    /*overflow: hidden;*/
}
.location-preview img {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.location-preview .image-overlay {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    transition: opacity 0.5s ease-in-out;
}
.location-preview .image-overlay:hover {
    opacity: 0.7;
}
.location-preview .image-overlay .MuiSvgIcon-root {
    font-size: 70px;
    color: var(--background);
}
@media screen and (max-width: 500px){
    .location-item {
        flex-direction: column;
    }
    .location-preview {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .location-item .location-details{
        text-align: center;
    }
    .location-item .location-details .location-address {
        flex-direction: column;
        align-items: center;
    }
    .location-item .location-details .location-address a {
        margin-left: 0;
    }
}
