.progress-bar {
    position: relative;
    display: block;
    width: 100%;
    height: 24px;
    background-color: #eff2f9;
    border-radius: 3px;
}
.progress-bar .progress-indicator {
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    background-color: var(--primary);
    border-radius: 3px;
    transition: width 1s ease-in-out;
}
