.content-preview {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    width: 200px;
    height: 100px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    transition: background-color 1s ease-out;
}
.content-preview .html-preview,
.content-preview .countdown-preview{
    transform-origin: top left;
    transform: scale(0.4);
    width: 500px;
    margin: 0 auto;
}
.content-preview .locations-preview,
.content-preview .rooms-preview,
.content-preview .timeline-preview{
    transform-origin: top left;
    transform: scale(0.285);
    width: 700px;
}
.content-preview .personIntro-preview {
    transform-origin: top left;
    transform: scale(0.222);
    width: 900px;
}
.content-preview .image-preview,
.content-preview .image-preview img{
    width: 200px;
    height: 100px;
    margin: 0;
    object-fit: cover;
}
.content-preview .signup-preview {
    background-color: var(--secondary);
    color: var(--contrast-text);
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-context-menu {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    display: flex;
    z-index: 1;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
