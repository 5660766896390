.empty-page {
    border: 2px dashed rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    width: 200px;
    height: 100px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.23);
    cursor: pointer;
}
.empty-page:hover {
    color: var(--primary);
    border: 2px dashed var(--primary);
}
