.timeline .timeline-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    width: 90px;
    height: 90px;
    border-radius: 45px;
    border: 10px solid var(--background);
    position: relative;
    z-index: 2;
}
.timeline.even .timeline-icon {
    border: 10px solid var(--backgroundPrimaryLight);
}
.timeline .timeline-details {
    position: relative;
    left: 50%;
    width: 300px;
    top: -80px;
    align-self: flex-start;
    margin-left: 80px;
    margin-bottom: -80px;
}
@media screen and (max-width: 800px) and (min-width: 500px){
    .timeline .timeline-details {
        left: 50px;
        width: calc(100% - 200px);
    }
}

@media screen and (min-width: 800px){
    .timeline .timeline-details.even {
        left: unset;
        right: 50%;
        text-align: right;
        align-self: flex-end;
        margin-left: unset;
        margin-right: 80px;
    }

}
@media screen and (max-width: 499px){
    .timeline .timeline-icon {
        margin: 20px auto 0 auto;
    }
    .timeline .timeline-details {
        position: unset;
        margin-left: 0;
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
        padding: 0 40px;
    }
}
